.siteHeader { position: fixed; z-index: 2020; top: 20px; right: 20px; left: 20px; overflow: hidden; background: $dgray; line-height: 100px; transition: 400ms $ease-in-out-cubic; color: lighten($gray, 30%); 
  @include media($desktop-narrow) { top: 0; right: 0; left: 0; }
  &.is-Smaller { line-height: 80px; background: $white; transform: translateY(-20px); color: $gray;
    .logo {
      img { transform: translateY(-65px); }
    }
    img { opacity: 0;
      & + img { opacity: 1; }
    }
    
    @include media($desktop-narrow) { transform: none; }
    @include media($tablet-wide) {
      .logo {
        img { transform: translateY(-45px); }
      }
    }
  }

  @include media($tablet-wide) { line-height: 65px !important; }
}

.logo { color: darken($white, 40%); font-size: 24px; margin: 0; float: left; color: $brand; margin-top: 18px; transition: 400ms $ease-in-out-cubic; width: 200px; height: 60px;
  a { display: block; }
  img { display: block; width: auto; max-width: none; transition: 400ms $ease-in-out-cubic;
    & + img { opacity: 0; width: 160px; 
      @include media($tablet-wide) { width: 150px; }
    }
  }

  @include media($tablet-wide) { width: 40px; height: 45px; overflow: hidden; margin: 10px 0; 
    img { width: 150px; }
  }
}

.mainNav { @include inlineNav; font-family: $title-font; text-transform: uppercase; font-weight: $semibold; @include span-columns(9); float: right; white-space: nowrap;
  @include media($tablet-wide) { float: none; display: block; margin: 0; width: auto; padding-left: 5px; overflow-y: auto; }

  ul { display: table; width: 100%; overflow-x: auto; }
  li { display: table-cell; width: 120px; 
    &.current-menu-item {
      a { color: $brand; }
    }
  }
  a { display: block; text-decoration: none; color: inherit; font-size: 16px; text-align: center; transition: background 300ms; overflow: hidden; position: relative; padding: 0 20px; background: alpha-color($white, 0); 
    &:before { content: ""; height: 2px; position: absolute; width: 0%; left: 0; bottom: 0; background: $accent; display: block; z-index: 10; transition: 400ms $ease-in-out-expo; }
    &:hover { color: $white; background: alpha-color($white, .03); 
      .is-Smaller & { color: $accent; }
      &:before { width: 100%; }
    }

    @include media($tablet-wide) { font-size: 14px; padding: 0 10px; }
  }

  &__Languages {
    a { display: inline-block; vertical-align: middle; position: relative; padding: 0 10px 0 12px; overflow: visible;
      &:hover { background: transparent; }
      &:before { display: none; }
      &:after { height: 23px; width: 1px; background: $brand; position: absolute; top: 50%; margin-top: -13px; left: -1px; content: ""; }
      &:first-child:after { display: none; }
      &.current-language { font-weight: $bold; color: $brand; 
        &:hover { color: $brand; }
      }
    }
  }
}