// Dev specific
#__bs_notify__ { position: fixed; top: 50%; left: 50%; margin-left: -125px !important; width: 250px; height: 50px; border-radius: 0 !important; opacity: 0.5; }

// BLOCKS
  // Globals
  .g-None { display: none; }
  .g-Hidden { visibility: hidden; }

// Template styles
.site { position: relative;
  &:before, &:after { content: ""; display: block; width: 20px; top: 0; bottom: 0; background: $white; position: fixed; z-index: 2000; }
  &:before { left: 0; }
  &:after { right: 0; }
  @include media($desktop-narrow) {
    &:before, &:after { display: none; }
  }
  &__Wrapper { padding-top: 100px;
    &:before, &:after { content: ""; display: block; height: 20px; left: 0; right: 0; background: $white; position: fixed; z-index: 2000; }
    &:before { top: 0; }
    &:after { bottom: 0; }
    @include media($desktop-narrow) {
      &:before, &:after { display: none; }
    }
    @include media($tablet-wide) { padding-top: 65px; }
  }

}

@keyframes fadeVideo {
  0%, 50% { opacity: 0; background: $black; }
  100% { opacity: 1; background: transparent; }
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.intro { position: relative; overflow: hidden; z-index: -1; background-size: cover; background-position: center; display: block; width: 100%; height: 60vh; min-height: 600px;
  @include media($tablet-wide) { height: auto; min-height: 0; }
  &:before {  opacity: 0.6; }
  &__Table { display: table; height: 100%; width: 100%; }
  &__Center { display: table-cell; vertical-align: middle; padding: 5% 40px;
    @include media($tablet-wide) { padding: 12% 40px; }
  }
  &__Content { @include outer-container; z-index: 10; max-width: $max-width - 50;  width: 100%; }
  &__Title { color: $white; font-size: 80px; font-size: 4vw; width: 100%; margin: 0; line-height: 1.1; font-family: $title-font; text-transform: uppercase; font-weight: $semibold; max-width: 50%;
    @include media($desktop) { font-size: 6vw; }
    @include media($tablet-wide) { font-size: 8vw; }
    @include media($tablet) { font-size: 10vw; }
    @include media($mobile) { font-size: 32px; }
  }
  &__Lead { font-size: 20px; margin: 0; color: $white; font-weight: $light; max-width: 600px;
    @include media($tablet) { font-size: 16px; }
    p { margin-top: 0; }
    img { margin: 20px 20px 0 0; }
  }
  &__Video { @include center(x); min-width: 100%; min-height: 100%; width: auto; height: auto; top: 0; z-index: -2; animation: 3s fadeVideo;
    @include media($tablet) { display: none !important; }
  }
}

.button { @include moreButton;
  &--Dark { @extend .button; @include moreButton($dbrand); }
}

.section { background: darken($white, 5%) url(../images/struckaxiom.png) repeat top center; position: relative; z-index: 1;
  &__Subtitle { font-family: $title-font; text-transform: uppercase; font-size: 18px; color: lighten($gray, 20%); font-weight: $mediumbold; text-align: center; margin-bottom: 10px;
    &:before { content: ""; display: block; height: 60px; width: 1px; background: $brand; margin: 20px auto; }

    &--Brand { @extend .section__Subtitle; color: $brand;
      // &:before { background: $brand; }
    }
    &--White { @extend .section__Subtitle; color: $white;
      // &:before { background: $white; }
    }
    &--Accent { @extend .section__Subtitle; color: $accent;
      // &:before { background: $accent; }
    }
  }
  &__Title { font-family: $title-font; text-transform: uppercase; font-size: 70px; font-size: 4vw; color: lighten($gray, 20%); font-weight: $semibold; text-align: center; margin: 0; line-height: 1.1;
    &:first-child { margin-top: 2%;
      @include media($tablet) { margin-top: 6%; }
    }

    @include media($tablet) { font-size: 6vw; }

    &--White { @extend .section__Title; color: $white; }
    &--Brand { @extend .section__Title; color: $brand; }
    &--Accent { @extend .section__Title; color: $accent; }
  }
  &__Content { background: $white; padding: 3% 0 4.6% 0;
    &--Contact { @extend .section__Content; background: $dbrand; color: $white; padding: 100px 0 120px 0; }
    &--DarkBrand { @extend .section__Content; background: $dbrand; color: $white; }
  }
    &__ContentWrapper { max-width: 800px; margin: 0 auto;
      &--CenterContents { @extend .section__ContentWrapper; display: flex; align-items: center; justify-content: center; height: 100%; }
      &--Wide { @extend .section__ContentWrapper; max-width: 1020px; }
      @include media($tablet-wide) { padding: 0 20px; }
    }
      &__ContentInner { font-size: 22px; text-align: center;
        @include media($tablet) { font-size: 18px; }
      }
    &__ContentRow { @include clearfix; margin-top: 100px; margin-left: 40px;
      @include media($tablet-wide) { margin-left: 0; margin-top: 60px; }
      &--without-Margin { @extend .section__ContentRow; margin: 0; }
    }
      &__ContentColumn { @include span-columns(4 of 8); @include omega(2n); margin-bottom: 60px;
        &--Title { @extend .section__ContentColumn; }
        &--Description { @extend .section__ContentColumn; }

        @include media($tablet) { @include fill-parent; margin-bottom: 20px; }
      }
        &__ContentColumnInner { font-size: 18px; margin-top: 0; color: lighten($gray, 20%);
          p { margin-top: 0; }

          &--font-Large { @extend .section__ContentColumnInner; font-size: 24px;
            @include media($tablet) { font-size: 20px; }
          }
          &--color-White { @extend .section__ContentColumnInner; color: $white; }
        }
        &__ContentColumnNumber { font-family: $title-font; font-weight: $regular; font-size: 22px; margin: 0;
          &:after { content: ""; display: block; width: 70px; background: $brand; height: 1px; margin: 5px 0 15px 0; }
        }
        &__ContentColumnTitle { font-size: 36px; font-size: 2vw; line-height: 1.1; margin: 0 0 10px; font-family: $title-font; text-transform: uppercase; font-weight: $semibold;
          @include media($tablet) { font-size: 20px; }
          @include media($mobile) { font-size: 18px; }
          &--with-MarginTop { @extend .section__ContentColumnTitle; margin-top: 8px; }
        }

  &__Blocks { @include outer-container; padding-top: 20%; padding-bottom: 80px;
    @include media($tablet-wide) { padding: 0; }
  }
  &__Block { width: 48%; float: left; background: $accent; padding: 8%; min-height: 500px; display: table; color: $white; margin: 1%; position: relative; z-index: 1; margin-top: -3%; transition: 0; outline: 0px solid transparent; text-decoration: none;

    &:link:hover { outline: 15px solid transparent; transition: 1s $ease-out-quint; }

    &:first-child { margin-top: -25%; }
    &:nth-child(odd) { margin-right: 50%;
      img { left: 100%; bottom: 10%; margin-left: -30px; margin-right: 0; }
    }
    &:nth-child(even) { margin-left: 50%;
      img { right: 100%; bottom: 10%; margin-right: -30px; margin-left: 0; }
    }

    &:nth-child(4n + 1) { background: $accent; outline-color: lighten($accent, 10%); }
    &:nth-child(4n + 2) { background: $brand; outline-color: lighten($brand, 10%); }
    &:nth-child(4n + 3) { background: $accent2; outline-color: lighten($accent2, 10%); }
    &:nth-child(4n + 4) { background: $white; color: $dgray; outline-color: lighten($white, 10%);  }
    &--without-Image { @extend .section__Block; margin: 1% !important;
      &:nth-child(odd) { margin-top: -3% !important;
        @include media($tablet-wide) { margin: 0 !important; }
      }
      &:nth-child(even) { margin-top: 1% !important;
        @include media($tablet-wide) { margin: 10px 0 !important; }
      }
    }

    @include media($tablet-wide) { display: block; width: auto !important; float: none; margin: 10px auto !important; min-height: 0; padding: 0; max-width: 600px; }
  }
    &__BlockImage { @include center(y); max-width: 600px; width: 110%;
      @include media($tablet-wide) { position: static; width: 100%; transform: none; margin: 0 !important; }
    }
    &__BlockContent { display: table-cell; vertical-align: middle; padding: 8%;
      p { font-size: 18px;
        @include media($tablet-wide) { font-size: 16px; }
      }
    }
    &__BlockTitle { font-family: $title-font; text-transform: uppercase; font-size: 24px; font-weight: $semibold; border-color: inherit;
      &:before { width: 60px; content: ""; box-shadow: 0 1px 0; display: block; vertical-align: middle; margin-top: -1px; margin-right: 20px; height: 1px; margin-bottom: 20px; }

      &--DarkGray { @extend .section__BlockTitle; color: $dgray;
        &:before { background: $dgray; }
      }

      @include media($tablet-wide) { font-size: 20px; }
    }

  &__List { margin: 0; padding: 0; padding-top: 10px; text-align: center; @include clearfix; margin-left: -30px; margin-right: -30px;
    @include media($tablet-wide) { margin: 0; padding-top: 0; padding-bottom: 5%; }
    a { color: $accent; text-decoration: none;
      &:hover { color: $dbrand; }
    }
  }
    &__ListTitle { font-size: 20px; margin-bottom: 0; line-height: 1.2; font-weight: $semibold; opacity: 0.6; margin-bottom: 10px;
      a { color: $dbrand;
        &:hover { text-decoration: underline; }
      }
    }
    &__ListItem { @include span-columns(3 of 9); @include omega(3n); font-size: 18px; text-align: left; vertical-align: top; padding: 40px 0 0 0;

      @include media($mobile) { @include fill-parent; margin: 0; padding: 10px 0; text-align: center; }
    }
    &__ListContent { color: lighten($gray, 20%); font-size: 15px;
      p { margin-top: 10px; }
    }

    &__Overlay { position: absolute; z-index: -1; top: 0; right: 0; bottom: 0; left: 0; background: $black; opacity: 0.5; }

  // SECTION: Modifiers
  &--Image { @extend .section; height: 100vh; min-height: 50vh; max-height: 600px; background-position: center; background-size: cover; background-repeat: no-repeat; color: $white;
    img { display: none; }
  }
}

.sectionNav { text-align: center; border-bottom: 1px solid darken($white, 8%);  background: $white; z-index: 1000; bottom: -1px; transition: 500ms $ease-in-out-cubic; left: 20px; right: 20px; padding-top: 2px;
  @include media($desktop-narrow) { left: 0; right: 0; }
  &__Progress { position: absolute; top: 0; left: 0; height: 3px; width: 100%; opacity: 0; visibility: hidden; border: none; appearance: none; color: $brand; background: none; transition: 300ms;
    &::-webkit-progress-bar {
      background: transparent;
    }

    &::-moz-progress-bar {
      background: transparent;
    }

    &::-webkit-progress-value {
      background: $brand;
    }

    .is-Fixed & { opacity: 1; visibility: visible; }
  }
  ul { margin: 0; padding: 0 10px; @include outer-container; white-space: nowrap; overflow-y: auto; }
  li { display: inline-block;
    &:first-child { display: none; }
    &.current-menu-item {
      a { color: $black; }
    }
  }
  svg { fill: $brand; width: 18px; margin-top: -5px; }
  a { display: block; text-decoration: none; padding: 0 20px; color: $gray; font-weight: $bold; font-size: 18px; line-height: 70px; transition: 200ms;
    @include media($tablet-wide) { line-height: 50px; font-size: 16px; padding: 0 10px; }
    &:hover { color: $brand;
      i { color: inherit; }
    }
  }
  i { display: block; font-size: em(32px); margin: 20px auto 0 auto; color: darken($white, 40%);
    .is-Fixed & { display: inline-block; font-size: em(18px); margin: 0; }
  }
  &.is-Cloned { transform: translateY(100%); position: fixed;
    li {
      &:first-child { display: inline-block; }
      &.current-menu-item {
        a { color: $brand; }
      }
      i { font-size: em(18px); display: inline-block; margin: 0 10px 0 0; }
    }

  }
  &.is-Fixed { transform: translateY(0%); }
}

.split { background-size: cover; background-position: center; width: 100%;
  &__Container { @include outer-container; display: flex; align-items: center; min-height: 80vh;
    @include media($tablet-wide) { min-height: 0; display: block; }
    &--Map { @extend .split__Container; max-width: none; height: auto; align-items: stretch; }
  }
  &__View { width: 50%; padding: 8%; background-size: cover; background-position: center; position: relative; color: lighten($gray, 20%);
    &--Left { @extend .split__View; margin-right: auto; }
    &--Right { @extend .split__View; margin-left: auto; }
    &--without-Padding { @extend .split__View; padding: 0 !important; }
    &--with-Overlay { @extend .split__View; @include overlay(0.7); color: $white; }

    @include media($tablet-wide) { display: block; width: auto; min-height: 200px; padding: 40px 20px 20px; }
  }


  form { @extend .form--Dark; }
  h1, h2 { font-family: $title-font; text-transform: uppercase; font-weight: $semibold; font-size: 62px; font-size: 3vw; margin: 0; line-height: 0.9; color: $black;
    &:before { content: ""; display: block; width: 70px; background: #E74C3C; height: 1px; margin: 5px 0 15px 0; }
    @include media($tablet) { font-size: 6vw; }
    @include media($mobile) { font-size: 24px; }
  }
  h2 { font-size: 36px; font-size: 2vw;
    @include media($tablet) { font-size: 24px; }
    @include media($mobile) { font-size: 18px; }
  }
  &__Map { height: 100%;
    h4 { margin: 10px 0 0;
      img { max-width: 120px; }
    }
    p { margin: 10px 0; }
    a { color: $brand; font-weight: $bold;
      &:hover { color: $black; }
    }

    @include media($tablet) { position: static; display: block; min-height: 350px; }
  }

  @include media($tablet-wide) { background: $white; background-image: none !important; }

  & + .split { border-top: 1px solid darken($white, 10%); border-bottom: 1px solid darken($white, 10%); margin-top: -1px; }
}

.reference { @include clearfix;
  &__Block { width: 50%; float: left; color: $white; background: $gray; background-size: 150%; background-position: center; transition: 300ms; @include overlay(0.6); border-bottom: 5px solid $white; height: 400px;
    @include media($tablet-wide) { height: 300px; }
    @include media($tablet) { width: auto; float: none; height: auto; }

    &:before { transition: 300ms; }
    &:hover { @include overlay(0.4); }

    &--Brand { @extend .reference__Block; @include overlay(0.9, $brand); }
  }
    &__Table { display: table; height: 100%; width: 100%; }
    &__BlockWrapper { display: table-cell; vertical-align: middle; border-right: 1px solid alpha-color($white, 0.1);
      &:nth-child(even) { border: 0; }
    }
    &__BlockContent { float: right; width: 100%; max-width: $max-width/2 + 10; padding: 30px 10% 0 20px;
      &--float-Left { @extend .reference__BlockContent; float: left; padding-right: 20px; padding-left: 10%; border: 0; }

      @include media($tablet) { max-width: none; float: none; padding: 40px 20px 0 20px; }
    }
    &__BlockInner {
      p { margin: 20px 0; }
    }
    &__Subtitle { font-family: $title-font; text-transform: uppercase; font-weight: $mediumbold; font-size: 18px; margin: 0; margin-bottom: -10px; color: $accent; }
    &__Title { font-family: $title-font; text-transform: uppercase; font-weight: $semibold; font-size: 36px; font-size: 2vw; margin: 0; margin-bottom: -10px; line-height: 1;
      @include media($tablet-wide) { font-size: 24px; }
    }

  &__Nav { text-align: center; clear: both; position: relative;
    a { display: block; text-decoration: none; color: $brand; padding: 25px; font-family: $title-font; text-transform: uppercase; font-size: 18px; transition: 200ms; border: 1px solid $brand; font-weight: $mediumbold;
      &:hover { background: $brand; color: $white; }
      @include media($tablet) { padding: 15px 0; }
    }
    span { display: block; animation: 500ms spinner infinite linear; position: absolute; top: 50%; left: 50%; margin-top: -10px; margin-left: -10px; width: 20px; height: 20px; font-size: 0;
      &:before { display: block; content: ""; width: 100%; height: 100%; border: 3px solid $brand; border-right: 3px solid transparent; border-radius: 50%; transition: 200ms $ease-in-out-back; opacity: 0; transform: scale(0); }
    }

    &.is-Visible {
      a { color: transparent; background: transparent; }
      span {
        &:before { opacity: 1; transform: scale(1); }
      }
    }
  }
}

.page { padding: 5% 0 8% 0; background: darken($white, 5%) url(../images/struckaxiom.png) repeat top center;
  &__Background { max-width: 800px; margin: 0 auto; line-height: 1.7; font-size: 18px; }
  &__Title { font-family: $title-font; font-size: 4vw; color: $accent; line-height: 1; }

  a { color: $brand; font-weight: $bold; text-decoration: none; border-bottom: 2px solid $brand;
    &:hover { color: $black; }
  }
}
