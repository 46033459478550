$icons: (
  sprite: (width: 1656px, height: 1656px, pngPath: '../images/sprite.png', svgPath: '../images/sprite.svg'),
  arrow-up: (width: 31.479px, height: 31.479px, backgroundX: 0px, backgroundY: 0px),
    facebook: (width: 430.113px, height: 430.114px, backgroundX: -32px, backgroundY: -32px),
    facebook-circle: (width: 49.652px, height: 49.652px, backgroundX: -463px, backgroundY: -463px),
    linkedin: (width: 430.117px, height: 430.117px, backgroundX: -513px, backgroundY: -513px),
    linkedin-circle: (width: 49.7px, height: 49.7px, backgroundX: -944px, backgroundY: -944px),
    twitter: (width: 612px, height: 612px, backgroundX: -994px, backgroundY: -994px),
    twitter-circle: (width: 49.652px, height: 49.652px, backgroundX: -1606px, backgroundY: -1606px),
  );

$sprite: map-get($icons, sprite) !default;
$baseFontSize: 16px !default;

// Gets an attribute from the sass map
@function sprite-attr($icon, $attr) {
  $newIcon: map-get($icons, $icon);
  @if $newIcon == null {
    @warn "Can't find an icon with the name #{$icon}";
  }
  @return map-get($newIcon, $attr);
}

@function icon-attr($icon) {
  $attr: (
    width: sprite-attr($icon, width),
    height: sprite-attr($icon, height),
    x: sprite-attr($icon, backgroundX),
    y: sprite-attr($icon, backgroundY)
    );
  @return $attr;
}

// Sets background image and size
%sprite { display: inline-block; vertical-align: middle; font-size: 0; font-style: normal; }

@mixin sprite($icon, $type: all) {
  $iconMap: icon-attr($icon);

  @extend %sprite;
  width: map-get($iconMap, width);
  height: map-get($iconMap, height);

  // Outputs background position in em
  @if $type != symbol {
    background-position: map-get($iconMap, x) map-get($iconMap, y);
    background-image: url(map-get($sprite, svgPath)); 
    background-size: map-get($sprite, width) map-get($sprite, height); 
  }
} 

svg, symbol { width: 0; height: 0;
  circle, ellipse, g, line, path, polygon, polyline, rect { fill: inherit !important; stroke: inherit !important; }
}

// .icon-arrow-up { @include sprite(arrow-up); }
.symbol-arrow-up { @include sprite(arrow-up, symbol); }
// .icon-facebook { @include sprite(facebook); }
.symbol-facebook { @include sprite(facebook, symbol); }
// .icon-facebook-circle { @include sprite(facebook-circle); }
.symbol-facebook-circle { @include sprite(facebook-circle, symbol); }
// .icon-linkedin { @include sprite(linkedin); }
.symbol-linkedin { @include sprite(linkedin, symbol); }
// .icon-linkedin-circle { @include sprite(linkedin-circle); }
.symbol-linkedin-circle { @include sprite(linkedin-circle, symbol); }
// .icon-twitter { @include sprite(twitter); }
.symbol-twitter { @include sprite(twitter, symbol); }
// .icon-twitter-circle { @include sprite(twitter-circle); }
.symbol-twitter-circle { @include sprite(twitter-circle, symbol); }
