.form {
  ul { margin: 0; padding: 0; }
  li { display: block; }
  #{$all-text-inputs}, textarea { width: 100%; padding: 15px; margin: 10px 0; border: 2px solid darken($white, 20%); transition: 200ms;
    &:focus { outline: none; border-color: $brand; }
  }
  &__Send { @include moreButton; padding: 0; margin-top: 10px;
    span { padding: 12px 60px; background: transparent; border: 0; text-transform: inherit; letter-spacing: inherit; display: block;
      &:focus { outline: none; }
    }
  }

  .gform_footer { margin: 0; padding: 0; }

  &--Dark { @extend .form; 
    #{$all-text-inputs}, textarea { background: lighten($black, 15%); border-color: lighten($black, 25%); }
  }
}