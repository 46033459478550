.siteFooter { background: $dgray; color: $white; @include clearfix; position: relative; overflow: hidden; position: relative; z-index: 1; padding: 3% 0;
  .center { position: relative; }
  &__Logo { margin: 0; padding: 0 40px 20px 0; max-width: 220px;
    @include media($tablet) { margin: 0 auto; padding: 20px 0; }
    i { display: block; }
    img { display: block; }
  }
  &__Column { @include span-columns(3);
    h2 { font-weight: $bold; font-size: em(13px); text-transform: uppercase;  color: $accent; }
    ul { margin: 0; padding: 0; }
    li { display: block; padding: 10px 0; border-bottom: 1px solid alpha-color($white, 0.1); }
    a { color: $white; text-decoration: none;
      &:hover { color: $brand; }
    }

    @include media($tablet) { display: none; @include fill-parent; text-align: center;
      &:first-child { display: block; padding-bottom: 20px; }
    }
  }
  &__Socials { margin: 0; padding: 0;
    ul { margin: 0; padding: 0; }
    li { display: inline-block; border: 0; }
    svg { fill: $white; width: 16px; height: 16px; vertical-align: middle; margin-top: -2px; }
    a { display: block; color: $white; text-decoration: none; transition: 200ms; width: 40px; line-height: 36px; text-align: center; border: 0; margin-right: 5px; border: 2px solid $accent;
      &:hover { opacity: 0.6; }
    }
  }
}
