// Alpha color
@function alpha-color($color: $black, $opacity: 0.5) { 
  $alpha-color-value: rgba($color, $opacity); 
  @return $alpha-color-value;
}

// Align to center
@mixin center($axis: "both"){
  position:absolute;
  @if $axis == "y"{
    top:50%;
    transform: translateY(-50%);
  }
  @if $axis == "x"{
    left:50%;
    transform: translateX(-50%);
  }
  @if $axis == "both"{
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
  }
}

// Target/context formula
@function percent($target, $context: $max-width) {
  $target-context-num: $target/$context * 100%;
  @return $target-context-num;
}

// EM formula
@function em($target, $context: $font-size) {
  $target-context-num: $target/$context * 1em;
  @return $target-context-num;
}

// Elements
@mixin inlineNav($ul: true) {
  @if $ul == true {
    ul { margin: 0; padding: 0; }
  }
  li { display: inline-block; vertical-align: top; }
}

// Scollbars
@mixin scrollbars($size: 8px, $foreground-color: darken($white, 20%), $background-color: darken($white, 10%)) {
  &::-webkit-scrollbar {
    width:  $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
    border-radius: 10px;
  }
}

// More button
  @mixin moreButton($fgcolor: $white, $bgcolor: $white, $fghover: $fgcolor, $bghover: $brand) { display: inline-block; padding: 12px 60px; background: transparent; color: $fgcolor; text-decoration: none; transition: 500ms 300ms; border: 0; text-transform: uppercase; font-weight: $mediumbold; font-family: $title-font; font-size: 16px; border: 1px solid $fgcolor; border-top: 0; border-bottom: 0; position: relative; overflow: hidden;

    &:before, &:after { content: ""; display: block; width: 100%; top: 0; left: 0; height: 1px; background: $fgcolor; position: absolute; transition: 300ms $ease-in-out-expo; }
    &:after { top: auto; bottom: 0; left: auto; right: 0; }

    // &:hover { background: $bghover; color: $fghover; border-color: $bghover; }
    &:hover { color: $fghover; 
      // &:before { transform: translateX(220%) skew(45deg); opacity: 0; }
      &:before, &:after { width: 50%; }
    }
  }
  .t-MoreButton { @include moreButton; }
  .t-SubscribeButton { @include moreButton; padding: 10px 15px; font-size: 13px; display: block;
    &--Brand { @include moreButton($brand, transparent); padding: 10px 15px; font-size: 13px; display: block; }
    &--White { @include moreButton($white, transparent, $black, $white); padding: 10px 15px; font-size: 13px; display: block; }
  }

// Input field
  @mixin inputField($fgcolor: $white, $bgcolor: transparent) { width: 100%; display: block; border: 0; padding: 12px; margin: 10px 0; border: 1px solid darken($fgcolor, 20%); background: $bgcolor; outline: none; border-radius: 3px; line-height: 20px;
    &:focus { border-color: $brand; border-width: 2px; padding: 11px; }
    &:-webkit-autofill { -webkit-box-shadow: 0 0 0 1000px $lgray inset; }

    .is-Dark & { border-color: lighten($black, 30%); 
      &:focus { border-color: $brand; }
    }

    &.is-True { color: darken($success, 10%); border-color: $success; border-width: 2px; }
    &.is-False { color: darken($error, 10%); border-color: $error; border-width: 2px; }
  }
  
// Overlay
  @mixin overlay($opacity: 0.5, $color: $black, $front: false, $element: before) { position: relative; z-index: 1;
    &:#{$element} { content: ""; display: block; position: absolute; top: 0; right: 0; bottom: 0; left: 0; background: alpha-color($color, $opacity); @if $front == true { z-index: 1; } @else { z-index: -1; } }
  }

// Loading spinner
 @mixin loader($size: 30px, $color: $accent, $center: false, $duration: 1s) { font-size: 0; width: $size; height: $size; border: 3px solid $color; border-left: 3px solid transparent; border-radius: 50%; animation: rotate $duration linear infinite; 

    @if $center == true { position: absolute; top: 50%; left: 50%; margin-left: -$size/2; margin-top: -$size/2; }
  }

 @mixin timer($size: 20px, $color: $accent, $center: false, $duration: 10s) { font-size: 0; width: $size; height: $size; border: 2px solid $color; border-radius: 50%; position: relative;
  &:before, &:after { content: ""; display: block; position: absolute; width: 2px; height: 7px; left: 7px; top: 7px; background: $color; transform-origin: 1px 1px; animation: rotate $duration linear infinite; border-radius: 5px; }
  &:after { animation: rotate 1s linear infinite; opacity: 0.6; height: 8px; }

    @if $center == true { position: absolute; top: 50%; left: 50%; margin-left: -$size/2; margin-top: -$size/2; }
  }